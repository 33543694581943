import React from 'react';

import { boxColorPreviewProps } from '../../../types';
import { BoxColor } from '../../styles';
import { ChromePicker } from 'react-color';
import { FaPaintBrush } from 'react-icons/fa';

export const BoxColorPreview: React.FC<boxColorPreviewProps> = ({
  color,
  setColorPreview,
  setShouldOpenPicker,
  shouldOpenPicker,
}) => {
  return (
    <BoxColor
      style={{
        background: color !== undefined ? (color as string) : '#FEFEFE',
        position: 'relative',
      }}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <FaPaintBrush
        title="Adicionar nova cor"
        style={{ cursor: 'pointer', zIndex: 9999999 }}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          setShouldOpenPicker(true);
        }}
      />
      {shouldOpenPicker && (
        <div style={{ position: 'absolute', top: 80, left: 50, zIndex: 99999 }}>
          <ChromePicker
            color={color}
            onChangeComplete={(cor) => setColorPreview(cor.hex)}
          />
        </div>
      )}
    </BoxColor>
  );
};
