import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const BootstrapModal = styled(Modal)`
  width: 500px;
  height: 100%;
  max-height: inherit;
  max-width: inherit;
  margin-left: inherit;
  margin-right: inherit;
  border-radius: 3px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  .modal-header {
    display: flex;
    flex-direction: row;
    span {
      margin-top: 14px;
    }
    .textFieldContainer {
      margin-left: 10px;
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-itens: flex-end;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;

    span {
      margin-bottom: 5px;
    }
  }

  .modal-footer {
    width: 100%;
    padding: 20px 10px;
    display: flex;
    flex-direction: row;
    color: #58079e;
    justify-content: space-between;
    border-top: none;
    border-top: 0px;
    .containerLeft {
      display: flex;
      justify-content: space-between;
    }
    .containerRight {
      display: flex;
      justify-content: flex-end;

      [class*='ButtonBase'] {
        margin-left: 5px;
      }
      button {
        margin-left: 5px;
      }
    }
  }
`;

export const BoxColor = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 10px;
  border: 1px solid #e1e4e7;
  padding: 5px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const PickerColorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-itens: center;
`;
export const ColorPicker = styled.span`
  height: 50px;
  width: 45px;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 4px;
  margin: 0px 9px 9px 0px;
  border: 1px solid #E1E4E7;
 
  :hover {
    box-shadow: 0 0 0 1px rgba(28, 39, 48, .5);, inset 0 0 0 2px rgba(28, 39, 48, .5);,
      inset 0 0 0 4px #fff;
  }
 
`;

export const WarningContainer = styled.div`
  width: 100%;
  height: 50px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff784 !important;

  svg {
    color: #fe904c !important;
    padding-right: 10px;
  }
`;
