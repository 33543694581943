import React from 'react';
import { FuncCardProps } from './types';

import { CardContextProvider } from './CardContext';
import CardContent from './CardContent';

const Card: React.FC<FuncCardProps> = ({
  codTelaProp,
  handleSearch,
  showTable,
  onShowTable,
  isOpenModal,
  setIsOpenModal,
  isUpdateCard,
  setUpdateCard,
  BackSearchProps,
  setBackSearchProps,
  setDataEdit,
  setIsCardFixed,
  isCardFixed,
  setLoading,
  textFiltro,
  tipoFiltro,
}: FuncCardProps) => {
  return (
    <CardContextProvider
      codTelaProp={codTelaProp}
      handleSearch={handleSearch}
      showTable={showTable}
      onShowTable={onShowTable}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      isUpdateCard={isUpdateCard}
      setUpdateCard={setUpdateCard}
      BackSearchProps={BackSearchProps}
      setBackSearchProps={setBackSearchProps}
      setDataEdit={setDataEdit}
      setIsCardFixed={setIsCardFixed}
      isCardFixed={isCardFixed}
      setLoading={setLoading}
      textFiltro={textFiltro}
      tipoFiltro={tipoFiltro}
    >
      <CardContent />
    </CardContextProvider>
  );
};

export default Card;
