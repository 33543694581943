import React, { useCallback, useEffect, useState } from 'react';
import {
  Control,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { InputSelect } from '~/components/NovosInputs';
import { useTableViewManager } from '~/context/tableViewManager';
import api from '~/services/api';

interface DefaultViewProps {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  cod_tela: number;
}

type View = {
  cod_tela: number;
  cod_usuario: number;
  cod_visao: number;
  des_visao: string;
  dta_ult_acesso: string;
  qtd_col_fixada: number;
  qtd_registro: number;
};

type ViewsResponse = {
  data: View[];
  success: boolean;
};

export const DefaultView: React.FC<DefaultViewProps> = ({
  register,
  control,
  setValue,
  cod_tela,
}) => {
  const [views, setViews] = useState<any[]>([]);
  const [optViews, setOptViews] = useState<any[]>([]);
  const { onChangeViewSelected, onChangeCodVisao } = useTableViewManager();

  const getViews = useCallback(async () => {
    const { data } = await api.get<ViewsResponse>(
      `/visualizacao/view/${cod_tela}`,
    );

    if (!data.success) {
      setOptViews([]);
      setViews([]);
      return;
    }

    const defaultOptions = [{ label: 'Nenhuma', value: null }];

    const options = [
      ...defaultOptions,
      ...data.data.map(({ des_visao, cod_visao }) => ({
        label: des_visao,
        value: cod_visao,
      })),
    ];

    const defaultViews = defaultOptions.map((option) => ({
      ...option,
      qtd_colunas_fixadas: 0,
      qtd_registro: 10,
    }));

    const view = [
      ...defaultViews,
      ...data.data.map(
        ({ des_visao, cod_visao, qtd_col_fixada, qtd_registro }) => ({
          label: des_visao,
          value: cod_visao,
          qtd_colunas_fixadas: qtd_col_fixada,
          qtd_registro,
        }),
      ),
    ];

    setViews(view);
    setOptViews(options);
  }, [cod_tela]);

  useEffect(() => {
    getViews();
  }, [getViews]);

  return (
    <InputSelect
      label="Visualização Padrão"
      placeholder="Nenhuma"
      name="visao"
      register={register}
      isDisabled={false}
      isError={false}
      control={control}
      options={optViews}
      changeSelected={(selected) => {
        setValue('visao', selected);

        const viewSelected = views.filter(
          (v) => v.label === selected.label && v.value === selected.value,
        )[0];

        onChangeViewSelected(viewSelected);
        onChangeCodVisao(Number(selected.value));
      }}
    />
  );
};
