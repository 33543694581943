import React from 'react';

import { PickerColorProps } from '../../../types';
import { PickerColorContainer, ColorPicker } from '../../styles';
import { nanoid } from 'nanoid';

const colorOption: any = [
  { cor: '#000000' },
  { cor: '#535353' },
  { cor: '#727272' },
  { cor: '#A5A5A5' },
  { cor: '#D8D8D8' },
  { cor: '#FEFEFE' },
  { cor: '#FE3030' },
  { cor: '#FE5656' },
  { cor: '#FE65C3' },
  { cor: '#CA6BE5' },
  { cor: '#8B51FE' },
  { cor: '#5D16EA' },
  { cor: '#0096B1' },
  { cor: '#0BBFDE' },
  { cor: '#5BE0E5' },
  { cor: '#37B5FE' },
  { cor: '#5170FE' },
  { cor: '#0049AC' },
  { cor: '#00BE62' },
  { cor: '#7DD856' },
  { cor: '#C0FE71' },
  { cor: '#FEDD58' },
  { cor: '#FEBC58' },
  { cor: '#FE904C' },
];

export const ColorContainer: React.FC<PickerColorProps> = ({
  setPickerColor,
}) => {
  return (
    <PickerColorContainer>
      {colorOption.map(({ cor }: any) => (
        <ColorPicker
          key={nanoid()}
          style={{ backgroundColor: cor }}
          onClick={() => {
            setPickerColor(cor);
          }}
        />
      ))}
    </PickerColorContainer>
  );
};
