import React, { useEffect, useState } from 'react';

import { WarningContainer } from '../../styles';
import { nanoid } from 'nanoid';
import { Fildset } from '~/components/Fildset';
import { useCards } from '~/components/Card/CardContext';
import { FaExclamationTriangle } from 'react-icons/fa';

export const TiposAcessos: React.FC = () => {
  const { tipoAcesso, handleChangeTipoAcesso } = useCards();

  const acessos: any[] = [
    { label: 'Privado', value: 0 },
    { label: 'Loja', value: 1 },
    { label: 'Grupo de Empresa', value: 2 },
    { label: 'Público', value: 3 },
  ];

  const [warningAcesso, setWarningAcesso] = useState('ok');

  useEffect(() => {
    let warningAcessoString = '';
    switch (tipoAcesso) {
      case 0:
        warningAcessoString =
          'Somente seu usuário terá acesso a essa consulta.';
        break;
      case 1:
        warningAcessoString =
          'Todos os usuários pertencentes a sua loja terão acesso a consulta.';
        break;
      case 2:
        warningAcessoString =
          'Todos os usuários pertencentes ao seu grupo de empresa terão acesso a consulta.';
        break;
      case 3:
        warningAcessoString = 'Disponível para toda a comunidade.';
        break;
      default:
        break;
    }

    setWarningAcesso(warningAcessoString);
  }, [tipoAcesso]);

  return (
    <div>
      <Fildset label="Acessos">
        <>
          {acessos.map((acesso: any) => (
            <div
              key={nanoid()}
              style={{ display: 'flex', gap: '0.5rem', margin: '0.5rem' }}
            >
              <input
                id={acesso.label}
                type="radio"
                checked={tipoAcesso === acesso.value}
                onClick={() => handleChangeTipoAcesso(acesso.value)}
              />
              <label htmlFor={acesso.label}>{acesso.label}</label>
            </div>
          ))}
        </>
      </Fildset>

      <WarningContainer>
        <div style={{ width: '50px' }}>
          <FaExclamationTriangle size={40} />
        </div>
        <div>
          <span>{warningAcesso}</span>
        </div>
      </WarningContainer>
    </div>
  );
};
